.card-title {
    font-size: 23px;
}

.text-end {
    text-align: left !important;
    font-size: 18px;
}

.card-header {
    /* background-color: linear-gradient(to#69A747; */
    background: linear-gradient(to bottom, #69A747, white);
    border: 0px
}